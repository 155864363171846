import http from './http-common'

class CustomerService {
  async getAllCustomers () {
    return (await http.get('/staff/customers/all')).data
  }

  async getCustomerOrderHistory (id) {
    return (await http.get(`/staff/${id}/order/history`)).data
  }

  async getAllBirthdayMonthCustomers () {
    return (await http.get('/staff/customer/all-customers-with-birthday-this-month')).data
  }
}

export default new CustomerService()
