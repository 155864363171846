<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="filters.name"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索"
          />
        </div>
      </b-col>
    </b-row>
    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:custom-action="{ item }">
        <div>
          <b-dropdown
            variant="link"
            toggle-class="text-white text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon
                icon="three-dots"
                font-scale="1.5"
                class="text-dark"
              ></b-icon>
            </template>
            <b-dropdown-item :to="'/user-management/' + item.id + '/order-history'">
              訂單歷史
            </b-dropdown-item>
            <b-dropdown-item :to="'/user-management/' + item.id + '/vouchers'">
              用戶優惠券
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </TableData>
    <Pagination
     v-if="!isLoading && paginatedData.length"
      v-model="currentPage"
      :list-data="filterCustomer"
      :limit="limit"
    />
  </b-container>
</template>

<script>
import customerService from '../services/customer.service'
import Pagination from '../components/Pagination.vue'
import TableData from '../components/TableData.vue'
export default {
  data: () => ({
    customerList: [],
    currentPage: 1,
    limit: 10,
    filters: {
      name: '',
      start: '',
      end: ''
    },
    headers: [
      { text: '會員編號', value: 'posMemberId' },
      { text: '使用者身分', value: 'id' },
      { text: '姓名', value: 'name' },
      { text: '電話號碼', value: 'phoneNumber' },
      { text: '日期登記', value: 'joinDate' },
      { text: '出生日期', value: 'birthdate' },
      { text: '操作', value: 'action' }
    ],
    isLoading: false
  }),
  components: {
    Pagination,
    TableData
  },
  mounted () {
    this.getAllCustomers()
  },
  computed: {
    filterCustomer () {
      return this.customerList.filter((item) =>
        this.filters.name.length ? item?.name?.toLowerCase()
          .replace(/\s+/g, '')
          .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.posMemberId?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.id?.toString().toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.phoneNumber?.toString().toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, ''))
          : true
      ).sort(
        (a, b) =>
          +new Date(b.joinDate) - (+new Date(a.joinDate))
      )
    },
    paginatedData () {
      return this.filterCustomer.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  watch: {
    filterCustomer () {
      this.currentPage = 1
    }
  },
  methods: {
    async getAllCustomers () {
      this.isLoading = true
      await customerService.getAllCustomers().then(
        (res) => {
          this.customerList = res.data
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          this.isLoading = false
        }
      )
      this.isLoading = false
    }
  }
}
</script>
